<template>
  <div>
    Resultaat <strong>
    {{ ((currentPage - 1) * perPage) + 1 }} - 
    {{ ((currentPage) * perPage) }}
    </strong> van <strong>
    {{ totalRows }}</strong> 
    items.
    (
      <template v-for="(count, index) in [20,50,100]">
        <span :key="'span'+index" v-if="index > 0"> - </span>
        <a :key="'a'+index" href="#" @click.prevent="setPerPage(count)">{{ count }}</a>
      </template>
    )
 
    <b-table
        v-if="currentPage > 0 && perPage > 0"
        striped
        hover
        ref="table"
        :items="itemProvider"
        :current-page="currentPage"
        :per-page="perPage"
        :fields="fields">

      <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template>

      <template v-slot:cell(idx)="data">
        {{ (( currentPage-1) * perPage) + data.index + 1}}
      </template>
    </b-table>

    <b-pagination
      v-if="totalRows > perPage && currentPage > 0"
      class="mt-4"
      :value="currentPage"
      @change="setPage"
      :total-rows="totalRows"
      :per-page="perPage"
      limit="11"
      aria-controls="my-table"
    ></b-pagination>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'RestTable',
    data () {
      return {
        cancelToken: null,
        loading: false,
        totalRows: 0,
        perPage: -1,
        currentPage: -1,
        perPageAfterRouteChange: null,
        currentFilter: '',
      }
    },
    props: {
      fields: Array,
      filter: Object,
      endpoint: String,
      startAt: Number,
    },
    watch: {
      filter: {
        deep: true,
        handler: function (cur) {
          const newFilterJson = JSON.stringify(cur)
          if (newFilterJson == this.currentFilter) return
          this.currentFilter = newFilterJson

          if (this.$refs.table) {
            this.$refs.table.refresh()
          }
        }
      },
      '$route' () {
        if (this.perPageAfterRouteChange) {
          this.perPage = this.perPageAfterRouteChange
          this.perPageAfterRouteChange = null
        }

        if (!this.startAt) return
        const page = Math.floor(this.startAt / this.perPage) + 1
        if (this.currentPage == page) return
        
        this.currentPage = page
      }
    },
    methods: {
      setPage (newPage, perPage = null) {
        if (newPage == this.currentPage) return
        if (perPage === null) {
          perPage = this.perPage
        }

        if (this.startAt) {
          if (perPage != this.perPage) {
            this.perPageAfterRouteChange = perPage
          }

          this.$router.push({ 
            query: {
              ...this.$route.query,
              'start-at': ((newPage-1) * perPage) + 1
            }
          }) 
        } else {
          this.currentPage = newPage
        }
      },
      setPerPage (perPage) {
        localStorage.setItem('tablePerPage', perPage)

        const startAt = ((this.currentPage-1) * this.perPage) + 1
        const page = Math.floor(startAt / perPage) + 1

        if (this.currentPage != page) {
          this.setPage(page, perPage)
        } else {
          this.perPage = perPage
        }
      },
      itemProvider (ctx) {
        return new Promise((resolve) => {
          if (this.cancelToken) {
            this.cancelToken.cancel()
          }
          this.cancelToken = axios.CancelToken.source()

          ctx['filter'] = this.filter

          this.loading = true
          this.$http
              .get(this.endpoint, { params: ctx, cancelToken: this.cancelToken.token })
              .then(response => {
                  this.totalRows = Number(response.headers['x-pagination-count'])
                  this.loading = false
                  
                  resolve(response.data)
              }).catch(function () {
                  resolve([])
              })
        })
      },
    },
    mounted () {
      let perPage = localStorage.getItem('tablePerPage')
      if (perPage) {
        this.perPage = Number(perPage)
      } else {
        this.perPage = 20
      }

      if (this.perPage < 1)
        this.currentPage = 1
      else if (this.startAt)
        this.currentPage = Math.floor(this.startAt / this.perPage) + 1
      else
        this.currentPage = 1

    }
  }
</script>

<style lang="scss">
  table td.link-icons {
    a {
      font-weight: normal;
      color: black;
    }

    font-size: 1.65rem;
    
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
</style>