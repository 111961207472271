var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._v(" Resultaat "),
      _c("strong", [
        _vm._v(
          " " +
            _vm._s((_vm.currentPage - 1) * _vm.perPage + 1) +
            " - " +
            _vm._s(_vm.currentPage * _vm.perPage) +
            " "
        )
      ]),
      _vm._v(" van "),
      _c("strong", [_vm._v(" " + _vm._s(_vm.totalRows))]),
      _vm._v(" items. ( "),
      _vm._l([20, 50, 100], function(count, index) {
        return [
          index > 0
            ? _c("span", { key: "span" + index }, [_vm._v(" - ")])
            : _vm._e(),
          _c(
            "a",
            {
              key: "a" + index,
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.setPerPage(count)
                }
              }
            },
            [_vm._v(_vm._s(count))]
          )
        ]
      }),
      _vm._v(" ) "),
      _vm.currentPage > 0 && _vm.perPage > 0
        ? _c(
            "b-table",
            {
              ref: "table",
              attrs: {
                striped: "",
                hover: "",
                items: _vm.itemProvider,
                "current-page": _vm.currentPage,
                "per-page": _vm.perPage,
                fields: _vm.fields
              },
              scopedSlots: _vm._u(
                [
                  _vm._l(_vm.$scopedSlots, function(_, name) {
                    return {
                      key: name,
                      fn: function(slotData) {
                        return [_vm._t(name, null, null, slotData)]
                      }
                    }
                  }),
                  {
                    key: "cell(idx)",
                    fn: function(data) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              (_vm.currentPage - 1) * _vm.perPage +
                                data.index +
                                1
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            },
            [
              _vm._l(_vm.$slots, function(_, name) {
                return _vm._t(name, null, { slot: name })
              })
            ],
            2
          )
        : _vm._e(),
      _vm.totalRows > _vm.perPage && _vm.currentPage > 0
        ? _c("b-pagination", {
            staticClass: "mt-4",
            attrs: {
              value: _vm.currentPage,
              "total-rows": _vm.totalRows,
              "per-page": _vm.perPage,
              limit: "11",
              "aria-controls": "my-table"
            },
            on: { change: _vm.setPage }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }